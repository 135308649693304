<template>
  <div class="subscriptions">
    <h3>Подписки</h3>
    <div
      v-if="subscriptionList && subscriptionList.length"
      class="subscriptions__list"
    >
      <div
        v-for="(subscription, index) in subscriptionList"
        :key="subscription.id"
        class="subscriptions__list-item"
      >
        <div :class="{ 'with-errors': userNotFoundInOtrs(subscription) }">
          • {{ getSubscriptionTitle(subscription) }}
          <br v-if="userNotFoundInOtrs(subscription)">
          <span
            v-if="userNotFoundInOtrs(subscription)"
            class="user-not-found"
          >
            Пользователь не найден в мастер системе
          </span>
        </div>
        <div class="subscriptions__list-item-actions">
          <esmp-button @click="editSubscription(subscription)" view="function">
            <esmp-icon name="doc-edit" />
          </esmp-button>
          <esmp-button @click="deleteSubscription(index)" view="function">
            <esmp-icon name="trash" />
          </esmp-button>
        </div>
      </div>
    </div>
    <div class="subscriptions__actions">
      <esmp-button
        size="small"
        view="outline"
        @click="onClickAddBtn"
      >
        Добавить подписку
      </esmp-button>
      <template v-if="canImportFromEsmpSm">
        <span class="ml-5 mr-5">или</span>
        <esmp-button
          size="small"
          view="function"
          @click="otrsSubscrModalShowed = true"
        >
          Импортировать из ESMP SM
        </esmp-button>
      </template>
    </div>
    <esmp-modal
      v-model="addSubscrModalShowed"
      class-name="subscriptions-modal"
    >
      <template #header>
        {{ subscriptionId ? 'Редактирование' : 'Добавление' }} подписки
      </template>
      <validation-observer
        ref="form"
        tag="div"
      >
        <esmp-select
          v-model="subscriptionType"
          @on-change="onTypeChange"
          placeholder="Тип подписки"
          filterable
        >
          <esmp-select-option
            v-for="item in subscriptionTypeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </esmp-select-option>
        </esmp-select>
        <validation-provider
          v-if="['addressesWithoutBranch', 'allAddressLevels'].includes(subscriptionType)"
          rules="required"
          name="«Узел»"
          v-slot="v"
          tag="div"
          class="mt-10"
        >
          <esmp-select
            v-model="nodeId"
            @on-change="getBranches"
            filterable
            placeholder="Узел"
            class="mt-10"
          >
            <esmp-select-option
              v-for="item in foundNodes"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </esmp-select-option>
          </esmp-select>
          {{ v.errors.length ? v.errors[0] : '' }}
        </validation-provider>
        <esmp-select
          v-if="subscriptionType === 'allAddressLevels'"
          v-model="branchId"
          placeholder="Ветка"
          filterable
          class="mt-10"
          :disabled="!nodeId"
        >
          <esmp-select-option
            v-for="item in branches"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </esmp-select-option>
        </esmp-select>
        <validation-provider
          v-if="['onlyAddresses', 'addressesWithoutBranch', 'allAddressLevels'].includes(subscriptionType)"
          :rules="{
            required: ['onlyAddresses', 'addressesWithoutBranch'].includes(subscriptionType)
          }"
          name="«Адрес»"
          v-slot="v"
          tag="div"
          class="mt-10"
        >
          <esmp-select
            v-model="addressId"
            placeholder="Адрес"
            :disabled="addressDisabled"
            filterable
            clearable
            :remote-method="debouncedSearchAddress"
          >
            <esmp-select-option
              v-for="item in addresses"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </esmp-select-option>
          </esmp-select>
          {{ v.errors.length ? v.errors[0] : '' }}
        </validation-provider>
        <validation-provider
          v-if="subscriptionType === 'onlyUsers'"
          rules="required"
          name="«Сотрудник»"
          v-slot="v"
          tag="div"
          class="mt-10"
        >
          <esmp-select
            class="user-search"
            v-model="employee"
            placeholder="Сотрудник"
            filterable
            :remote-method="debouncedSearchUser"
            @on-select="setUserDto"
          >
            <esmp-select-option
              v-for="item in employeeList"
              :key="item.login"
              :value="item.login"
              :label="item.fullName"
            >
              {{ item.fullName }}
              <span
                v-if="item.email"
                class="user-search__email"
              >
                {{ item.email }}
              </span>
            </esmp-select-option>
          </esmp-select>
          {{ v.errors.length ? v.errors[0] : '' }}
        </validation-provider>
      </validation-observer>
      <template #footer>
        <esmp-button @click="updateSubscr">
          Сохранить
        </esmp-button>
        <esmp-button view="outline" @click="addSubscrModalShowed = false">
          Отменить
        </esmp-button>
      </template>
    </esmp-modal>
    <esmp-modal
      v-model="otrsSubscrModalShowed"
      width="660"
    >
      <template #header>
        Импорт из Service Manager
      </template>
      <esmp-select
        placeholder="Подписка"
        multiple
        v-model="otsSubscrSelectedIds"
      >
        <esmp-select-option
          v-for="sub in otsSubscrList"
          :key="sub.id"
          :value="sub.id"
        >
          {{ sub.title }}
        </esmp-select-option>
      </esmp-select>
      <template #footer>
        <esmp-button @click="otrsImportAll">
          Импортировать все
        </esmp-button>
        <esmp-button @click="otrsImportSelected">
          Импортировать выбранные
        </esmp-button>
        <esmp-button view="outline" @click="otrsSubscrModalShowed = false">
          Отменить
        </esmp-button>
      </template>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';

const SUBSCRIPTION_TYPES = {
  allAddressLevels: 'allAddressLevels',
  addressesWithoutBranch: 'addressesWithoutBranch',
  onlyAddresses: 'onlyAddresses',
  onlyUsers: 'onlyUsers',
};

export default {
  name: 'SubscriptionList',
  model: {
    prop: 'subscriptions',
    event: 'change',
  },
  props: {
    subscriptions: {
      type: Array,
      default: () => [],
    },
    serviceId: {
      type: [Number, String],
      default: undefined,
    },
    canImportFromEsmpSm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      addSubscrModalShowed: false,
      otrsSubscrModalShowed: false,
      otsSubscrList: [],
      otsSubscrSelectedIds: [],
      subscriptionTypeList: [
        {
          name: 'Стандартная',
          value: 'allAddressLevels',
        },
        {
          name: 'Узел и адрес',
          value: 'addressesWithoutBranch',
        },
        {
          name: 'Только адрес',
          value: 'onlyAddresses',
        },
        {
          name: 'Сотрудник',
          value: 'onlyUsers',
        },
      ],
      subscriptionType: 'allAddressLevels',
      nodes: [],
      foundNodes: [],
      nodeId: null,
      branches: [],
      branchId: null,
      addresses: [],
      addressId: null,
      employee: '',
      employeeList: [],
      userDto: null,
      subscriptionId: null,
    };
  },
  computed: {
    subscriptionList: {
      get() {
        return this.subscriptions;
      },
      set(list) {
        this.$emit('change', list);
      },
    },
    addressDisabled() {
      if (this.subscriptionType === 'onlyAddresses') return false;
      if (this.subscriptionType === 'addressesWithoutBranch') {
        return !this.nodeId;
      }
      return !this.branchId;
    },
    subObjectDictionary() {
      const nodes = {};
      const branches = {};
      const addresses = {};
      this.subscriptionList.forEach((subscription) => {
        /* eslint-disable no-return-assign */
        if (subscription.nodes) {
          subscription.nodes.forEach((node) => (nodes[node.id] = node));
        }
        if (subscription.branches) {
          subscription.branches.forEach((branch) => (branches[branch.id] = branch));
        }
        if (subscription.addresses) {
          subscription.addresses.forEach((address) => (addresses[address.id] = address));
        }
        /* eslint-enable no-return-assign */
      });
      return {
        nodes,
        branches,
        addresses,
      };
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    onClickAddBtn() {
      this.foundNodes = this.nodes;
      this.nodeId = null;
      this.branches = [];
      this.branchId = null;
      this.addresses = [];
      this.addressId = null;
      this.employee = '';
      this.employeeList = [];
      this.userDto = null;
      this.subscriptionId = null;
      this.addSubscrModalShowed = true;
    },
    setUserDto(u) {
      this.userDto = this.employeeList.find((i) => i.login === u.value);
    },
    updateSubscr() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          // проверка дублей
          const hasSome = this.subscriptionList.some((sub) => {
            if (sub.type !== this.subscriptionType) return false;

            if (this.subscriptionType === SUBSCRIPTION_TYPES.onlyUsers) {
              return sub.userLogin === this.userDto?.login;
            }

            return sub.addressId === this.addressId
              && sub.nodeId === this.nodeId
              && sub.branchId === this.branchId;
          });
          if (hasSome) {
            this.$EsmpNotify.$show('Данная подписка уже существует', 'error');
            return;
          }

          let currentSubscription;
          switch (this.subscriptionType) {
          case SUBSCRIPTION_TYPES.allAddressLevels:
            currentSubscription = {
              nodeId: this.nodeId,
              branchId: this.branchId,
              addressId: this.addressId,
              nodes: this.nodes,
              branches: this.branches,
              addresses: this.addresses,
              type: this.subscriptionType,
            };
            break;
          case SUBSCRIPTION_TYPES.addressesWithoutBranch:
            currentSubscription = {
              nodeId: this.nodeId,
              nodes: this.nodes,
              addressId: this.addressId,
              addresses: this.addresses,
              type: this.subscriptionType,
            };
            break;
          case SUBSCRIPTION_TYPES.onlyAddresses:
            currentSubscription = {
              addressId: this.addressId,
              addresses: this.addresses,
              type: this.subscriptionType,
            };
            break;
          case SUBSCRIPTION_TYPES.onlyUsers:
            currentSubscription = {
              userDto: this.userDto,
              userLogin: this.userDto.login,
              type: this.subscriptionType,
            };
            break;
          default:
            break;
          }

          this.setLoading({ key: 'page', value: true });
          this.$API.forms.checkSubscriptions(
            this.$route.params.serviceId,
            [currentSubscription],
          ).then(() => {
            this.subscriptionList.push(currentSubscription);
            if (this.subscriptionId) {
              this.subscriptionList = this.subscriptionList.filter((el) => el.id !== this.subscriptionId);
            }
            this.addSubscrModalShowed = false;
          }).catch(() => {
            this.$EsmpNotify.$show('Данная подписка уже существует', 'error');
          }).finally(() => {
            this.setLoading({ key: 'page', value: false });
          });
        }
      });
    },
    convertSubscr(ids) {
      this.$API.subscriptions.importOtrsSubscr(ids, this.serviceId).then(({ data }) => {
        this.subscriptionList.push(...data);
        this.$EsmpNotify.$show('Подписки импортированы', 'success');
        this.otrsSubscrModalShowed = false;
      });
    },
    otrsImportAll() {
      this.convertSubscr(this.otsSubscrList.map((el) => el.id));
    },
    otrsImportSelected() {
      this.convertSubscr(this.otsSubscrSelectedIds);
    },
    deleteSubscription(index) {
      this.$emit('on-delete', index);
    },
    editSubscription(subscription) {
      this.subscriptionType = subscription.type;
      this.nodes = subscription.nodes;
      this.foundNodes = this.nodes;
      this.nodeId = subscription.nodeId;
      this.branches = subscription.branches;
      this.branchId = subscription.branchId;
      this.addresses = subscription.addresses;
      this.addressId = subscription.addressId;
      this.employee = subscription.userDto?.login;
      this.employeeList = [subscription.userDto];
      this.userDto = subscription.userDto;
      this.subscriptionId = subscription.id;
      this.addSubscrModalShowed = true;
    },
    userNotFoundInOtrs(subscription) {
      return !!(
        subscription
        && subscription.type === 'onlyUsers'
        && subscription.userNotFound
      );
    },
    getNodeName(nodeId) {
      const node = this.subObjectDictionary.nodes[nodeId];
      if (node) return node.name;
      console.warn(`node ${nodeId} was not found`);
      return '';
    },
    getBranchName(branchId) {
      const branch = this.subObjectDictionary.branches[branchId];
      if (branch) return branch.name;
      console.warn(`branch ${branchId} was not found`);
      return '';
    },
    getAddressName(addressId) {
      const address = this.subObjectDictionary.addresses[addressId];
      if (address) return address.name;
      console.warn(`node ${address} was not found`);
      return '';
    },
    getSubscriptionTitle(subscription) {
      let title = '';
      switch (subscription.type) {
      case SUBSCRIPTION_TYPES.allAddressLevels:
        title += this.getNodeName(subscription.nodeId);
        if (subscription.branchId) {
          title += ` ${this.getBranchName(subscription.branchId)}`;
        }
        if (subscription.addressId) {
          title += ` ${this.getAddressName(subscription.addressId)}`;
        }
        return title;
      case SUBSCRIPTION_TYPES.addressesWithoutBranch:
        title += this.getNodeName(subscription.nodeId);
        if (subscription.addressId) {
          title += ` ${this.getAddressName(subscription.addressId)}`;
        }
        return title;
      case SUBSCRIPTION_TYPES.onlyAddresses:
        title += this.getAddressName(subscription.addressId);
        return title;
      case SUBSCRIPTION_TYPES.onlyUsers:
        title += subscription.userDto
          ? subscription.userDto.fullName
          : subscription.userLogin;
        return title;
      default:
        return 'Неизвестный тип подписки';
      }
    },
    getLocationNodes() {
      this.$API.admin.getLocationNodes().then(({ data }) => {
        this.nodes = data;
        this.foundNodes = this.nodes;
      });
    },
    getBranches(nodeId) {
      if (!nodeId) {
        this.branchId = null;
        this.branches = [];
        return;
      }
      this.$API.admin.getBranches(nodeId).then(({ data }) => {
        this.branches = data;
      });
    },
    searchAddress(query) {
      if (query) {
        if (this.subscriptionType === 'allAddressLevels') {
          this.$API.admin.getAddressInBranch(this.nodeId, this.branchId, query).then(({ data }) => {
            if (data.length) this.addresses = data;
          });
        } else if (this.subscriptionType === 'addressesWithoutBranch') {
          this.$API.admin.getAddressInNode(this.nodeId, query).then(({ data }) => {
            if (data.length) this.addresses = data;
          });
        } else {
          this.$API.admin.getAddress(query).then(({ data }) => {
            if (data.length) this.addresses = data;
          });
        }
      } else {
        this.addresses = [];
      }
    },
    searchUser(query) {
      if (query) {
        this.$API.user.search({ query }).then(({ data }) => {
          this.employeeList = data;
        });
      } else {
        this.employeeList = [];
      }
    },
    onTypeChange() {
      this.nodeId = null;
      this.branchId = null;
      this.branches = [];
      this.addressId = null;
      this.addresses = [];
    },
  },
  created() {
    this.subscriptionType = SUBSCRIPTION_TYPES.allAddressLevels;
    this.debouncedSearchAddress = debounce(this.searchAddress, SEARCH_DELAY);
    this.debouncedSearchUser = debounce(this.searchUser, SEARCH_DELAY);
    if (this.serviceId) {
      this.$API.subscriptions.getOtrSubscr(this.serviceId).then(({ data }) => {
        const otsSubscrIds = data.map((el) => el.id);
        const uniqOtsSubscrIds = [...new Set(otsSubscrIds)];
        this.otsSubscrList = uniqOtsSubscrIds.map((el) => data.find((i) => i.id === el));
      });
    }
    this.getLocationNodes();
  },
};
</script>

<style lang="scss">
.subscriptions {
  &__list-item {
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
    border-bottom: 1px solid $color-gray-status-op-15;
    word-break: break-word;
  }

  &__list-item-actions {
    display: flex;
    flex-shrink: 0;

    .esmp-svg-icon {
      width: 18px;
      height: 18px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
}
.subscriptions-modal {
  .user-search {
    &__email {
      font-size: 12px;
      line-height: 16px;
      color: $color-gray-status-op-60;
    }
  }

  .esmp-select-disabled {
    opacity: .5;
    .esmp-select-head {
      background-color: $color-grayscale-40;
    }
  }
}
</style>
