<template>
  <div class="page page--offset page__forms forms">
    <esmp-table-wrapper
      class="forms-table"
      :title="tableTitle"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <esmp-table
        :columns="columns"
        :rows="forms"
        :loading="isLoading"
        :is-inner-bordered="false"
        stripe
        @on-sort="sortByName"
      >
        <template #cell-is-default="{ tr }">
          <esmp-icon v-if="tr.isDefaultForm" name="star2" />
        </template>

        <template #cell-name="{ tr }">
          {{ tr.name }}
        </template>

        <template #cell-actions="{ tr }">
          <div class="forms-table__action-buttons">
            <esmp-button
              v-if="!tr.isDefaultForm"
              size="small"
              icon="item"
              icon-position="left"
              @click="editSubscriptions(tr.id, tr.name)"
            >
              Подписка
            </esmp-button>

            <esmp-button-group>
              <esmp-button
                size="small"
                view="outline"
                icon="doc-edit"
                icon-position="left"
                title="Изменить"
                @click="changeForm(tr.serviceId, tr.id)"
              />

              <esmp-button
                size="small"
                view="outline"
                icon="16-copy"
                icon-position="left"
                title="Копировать"
                @click="duplicateForm(tr.id)"
              />
              <esmp-button
                size="small"
                view="outline"
                icon="workflow"
                icon-position="left"
                title="Версии"
                @click="showFormVersions(tr.id)"
              />

              <esmp-button
                size="small"
                view="outline"
                icon="trash"
                icon-position="left"
                title="Удалить"
                @click="showConfirmModal(tr.id)"
              />

              <esmp-button
                size="small"
                view="outline"
                icon="24-cloud-download"
                icon-position="left"
                title="Экспорт"
                @click="exportForm(tr)"
              />
            </esmp-button-group>
          </div>
        </template>
      </esmp-table>
    </esmp-table-wrapper>
    <div
      :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
    >
      <esmp-button
        class="page__action-button"
        icon="plus"
        icon-position="left"
        @click="createForm(false)"
      >
        Добавить
      </esmp-button>

      <esmp-button
        class="page__action-button"
        view="outline"
        icon="copy2"
        icon-position="left"
        @click="copyModalShowed = true"
      >
        Скопировать
      </esmp-button>

      <esmp-button
        class="page__action-button"
        view="outline"
        icon="24-cloud-upload"
        icon-position="left"
        @click="createForm(true)"
      >
        Импортировать
      </esmp-button>
    </div>
    <esmp-modal
      v-model="confirmModalShowed"
      class="modal-confirm"
      @on-ok="deleteForm"
    >
      <template #header>
        Удаление формы
      </template>
      После удаления данное действие нельзя будет отменить. Вы уверены?
    </esmp-modal>
    <esmp-modal
      v-model="createModalShowed"
      width="525px"
    >
      <template #header>
        {{ createModalTitle }}
      </template>
      <subscription-list
        v-model="currentForm.subscriptions"
        :service-id="$route.params.serviceId"
        @on-delete="deleteSubscription"
      />
      <esmp-input
        label="Название формы"
        v-model="currentForm.name"
        class="mt-20"
      />
      <template #footer>
        <esmp-button :disabled="smImportButtonDisabled">
          Импорт из SM
        </esmp-button>
        <esmp-button view="outline" @click="createModalShowed = false">
          Отменить
        </esmp-button>
        <esmp-button @click="addForm(importFormMode)">
          {{ importFormMode ? 'Импортировать' : 'Создать' }}
        </esmp-button>
      </template>
    </esmp-modal>
    <esmp-modal
      v-model="copyModalShowed"
    >
      <template #header>
        Копирование формы из другой услуги
      </template>
      <esmp-select
        placeholder="Сервис"
        filterable
        :remote-method="debounceSearchServices"
        @on-select="onSelectService"
      >
        <esmp-select-option
          v-for="item in searchServicesResult"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <esmp-select
        placeholder="Форма"
        :disabled="copyFormDisabled"
        @on-select="onSelectForm"
        class="mt-20"
      >
        <esmp-select-option
          v-for="item in selectedServiceForms"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <template #footer>
        <esmp-button :disabled="copyFormDisabled" @click="copyForm">
          Копировать
        </esmp-button>
        <esmp-button view="outline" @click="copyModalShowed = false">
          Отменить
        </esmp-button>
      </template>
    </esmp-modal>
    <esmp-modal
      v-model="subscriptionsModalShowed"
    >
      <template #header>
        Редактирование подписок
      </template>
      <subscription-list
        v-if="subscriptionsModalShowed"
        v-model="currentForm.subscriptions"
        :service-id="$route.params.serviceId"
        @on-delete="deleteSubscription"
      />
      <esmp-input
        label="Название формы"
        v-model="currentForm.name"
        class="mt-20"
      />
      <template #footer>
        <esmp-button @click="updateForm">
          Сохранить
        </esmp-button>
        <esmp-button view="outline" @click="subscriptionsModalShowed = false">
          Отменить
        </esmp-button>
      </template>
    </esmp-modal>
    <form-versions
      :is-showed="formVersionsShowed"
      :form-versions-id="formVersionsId"
      header="Версии формы"
      @hide-form-versions-modal="hideFormVersionsModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { saveAs } from 'file-saver';
import { MAX_SIZE_UPLOAD_JSON } from '@/constants/uploadFiles';
import { SEARCH_DELAY } from '@/constants/search';
import SubscriptionList from '@/pages/admin/forms/components/SubscriptionList.vue';
import FormVersions from '@/pages/admin/forms/components/FormVersions.vue';

export default {
  name: 'Forms',
  components: { SubscriptionList, FormVersions },
  data() {
    return {
      service: {},
      forms: [],
      columns: Object.freeze([
        {
          title: '',
          key: 'is-default',
        },
        {
          title: 'Название',
          key: 'name',
          sortable: true,
        },
        {
          title: '',
          key: 'actions',
        },
      ]),
      isLoading: true,
      createModalShowed: false,
      copyModalShowed: false,
      confirmModalShowed: false,
      subscriptionsModalShowed: false,
      removedFormId: null,
      searchServicesResult: [],
      selectedServiceForms: [],
      copyFormId: null,
      currentForm: {
        name: '',
        id: null,
        subscriptions: [],
      },
      importFormMode: false,
      dublicatedFormId: undefined,
      formVersionsShowed: false,
      formVersionsId: null,
      filesForFormUpload: [],
    };
  },
  watch: {
    createModalShowed(val) {
      if (!val) this.dublicatedFormId = undefined;
    },
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    serviceName() {
      return this.service.name;
    },
    tableTitle() {
      return this.serviceName ? `Формы: ${this.service.name}` : 'Формы';
    },
    copyFormDisabled() {
      return !this.selectedServiceForms.length;
    },
    smImportButtonDisabled() {
      return !this.currentForm?.subscriptions?.length;
    },
    createModalTitle() {
      // eslint-disable-next-line no-nested-ternary
      return this.dublicatedFormId
        ? 'Копирование формы'
        : this.importFormMode
          ? 'Импортирование формы'
          : 'Создание формы';
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('subscriptions', ['setSubscriptions']),
    createForm(importFormMode) {
      this.currentForm = {
        id: null,
        name: '',
        subscriptions: [],
      };
      this.createModalShowed = true;
      this.importFormMode = importFormMode;
    },
    updateForm() {
      this.setLoading({ key: 'page', value: true });
      this.$API.forms.updateFormInfo({
        formSubscriptions: this.currentForm.subscriptions,
        id: this.currentForm.id,
        name: this.currentForm.name,
      }).then(({ data }) => {
        this.setLoading({ key: 'page', value: false });
        this.$EsmpNotify.$show('Форма обновлена', 'success');
        const formInd = this.forms.findIndex((f) => f.id === this.currentForm.id);
        if (formInd !== -1) {
          this.$set(this.forms, formInd, data);
        }
        this.subscriptionsModalShowed = false;
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
    deleteSubscription(index) {
      this.currentForm.subscriptions = this.currentForm.subscriptions.filter((s, i) => i !== index);
    },
    editSubscriptions(id, formName) {
      this.setLoading({ key: 'page', value: true });
      this.$API.subscriptions.getSubscriptions(id).then(({ data }) => {
        this.currentForm = {
          id,
          name: formName,
          subscriptions: data,
        };
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
        this.subscriptionsModalShowed = true;
      });
    },
    addForm(importFormMode = false) {
      if (!this.currentForm.subscriptions.length) {
        this.$EsmpNotify.$show('Отсутствуют подписки', 'error');
        return;
      }
      if (!this.currentForm.name) {
        this.$EsmpNotify.$show('Не заполнено название формы', 'error');
        return;
      }
      this.setSubscriptions(this.currentForm.subscriptions);
      this.$router.push({
        name: importFormMode ? 'ImportForm' : 'CreateForm',
        params: {
          serviceId: this.$route.params.serviceId,
        },
        query: { name: this.currentForm.name, dublicatedFormId: this.dublicatedFormId },
      });
    },
    copyForm() {
      this.setLoading({ key: 'page', value: true });
      this.$API.forms.copyForm(this.copyFormId, this.$route.params.serviceId).then(({ data }) => {
        this.forms = [...this.forms, data].sort((a, b) => a.name.localeCompare(b.name));
        this.copyModalShowed = false;
        this.copyFormId = null;
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
    async exportForm({ id, serviceId, name }) {
      this.setLoading({ key: 'page', value: true });

      try {
        const fileName = `form-${serviceId}-${name}-${id}.json`;
        const { data: file } = await this.$API.admin.exportForm(id);
        const blob = new Blob(
          [JSON.stringify(file)],
          { type: 'text/plain;charset=utf-8' },
        );

        saveAs(blob, fileName);
      } finally {
        this.setLoading({ key: 'page', value: false });
      }
    },
    onSelectForm(item) {
      this.copyFormId = item.value;
    },
    onSelectService(item) {
      this.getForms(item.value).then(({ data }) => {
        this.selectedServiceForms = data.filter((f) => !f.isDefaultForm);
      });
    },
    searchServices(query) {
      if (query) {
        this.$API.services.simpleSearch(query).then(({ data }) => {
          this.searchServicesResult = data;
        });
      } else {
        this.searchServicesResult = [];
      }
    },
    showConfirmModal(id) {
      this.removedFormId = id;
      this.confirmModalShowed = true;
    },
    deleteForm() {
      this.setLoading({ key: 'page', value: true });
      this.$API.forms.deleteForm(this.removedFormId).then(() => {
        this.forms = this.forms.filter((f) => f.id !== this.removedFormId);
        this.$EsmpNotify.$show('Форма удалена', 'success');
      }).finally(() => {
        this.removedFormId = null;
        this.setLoading({ key: 'page', value: false });
      });
    },
    getServiceInfo(serviceId) {
      return this.$API.services.getServiceInfo(serviceId);
    },
    getForms(serviceId) {
      return this.$API.forms.getForms(serviceId);
    },
    sortByName(val) {
      if (val.type === 'asc') {
        this.forms.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.forms.sort((a, b) => b.name.localeCompare(a.name));
      }
    },
    changeForm(serviceId, id) {
      const route = this.$router.resolve({ path: `/admin/forms/${serviceId}/edit/${id}` });
      window.open(route.href);
    },
    duplicateForm(formId) {
      this.createForm();
      this.dublicatedFormId = formId;
    },
    showFormVersions(formId) {
      this.formVersionsId = formId;
      this.formVersionsShowed = true;
    },
    hideFormVersionsModal() {
      this.formVersionsShowed = false;
      this.formVersionsId = null;
    },
  },
  created() {
    this.MAX_SIZE_UPLOAD_JSON = MAX_SIZE_UPLOAD_JSON;
    this.debounceSearchServices = debounce(this.searchServices, SEARCH_DELAY);
    const { serviceId } = this.$route.params;
    Promise
      .all([this.getServiceInfo(serviceId), this.getForms(serviceId)])
      .then(([res1, res2]) => {
        this.service = res1.data;
        this.forms = res2.data.sort((a, b) => a.name.localeCompare(b.name));
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>
<style lang="scss">
.forms-table {
  &__actions-item {
    color: $color-black;
  }

  &__action-buttons {
    display: flex;
    gap: $gap / 2;
    justify-content: flex-end;

    .esmp-button-group {
      .esmp-button {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
  .esmp-table {
    &__thead {
      display: none;
    }
    &__tbody-td {
      &:first-child {
        width: 24px;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(7) {
        width: 150px;
      }

      &:nth-child(6) {
        width: 100px;
      }
    }
  }
}
</style>
